import { Avatar, Card, CardActions, CardContent, CardMedia, Container, Typography } from "@mui/material";
import React from "react";


export default ThankComponent;
function ThankComponent({ npsInfo }) {

    return (<Container>

        {/* imagem */}
        <div>
            <Card sx={{ maxWidth: 345 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CardMedia
                        component="img"
                        alt="brand"
                        style={{ marginTop: 12, padding: 2, height: "60%", width: `70%` }}
                        image={npsInfo.doc.npsconfig.urlimage}
                        sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                    />
                </div>
                <CardContent>
                    <p style={{ fontFamily: 'Quasimoda', fontSize: 18 }}>
                        {npsInfo.doc.npsconfig.thanktitle}
                    </p>
                    <p style={{ fontFamily: 'Quasimoda', fontSize: 12 }}>
                        {npsInfo.doc.npsconfig.thanktext}
                    </p>
                    <p style={{ fontFamily: 'Quasimoda', fontSize: 12 }}>
                        {npsInfo.doc.npsconfig.thankshowsellerinfo ? npsInfo.doc.npsconfig.thanksellerinstruction : ''}
                    </p>
                </CardContent>
                <CardActions>
                    {npsInfo.doc.npsconfig.thankshowsellerinfo && <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row'
                        }}>
                        <div>
                            <Avatar />
                        </div>
                        <div>
                            <div style={{ marginLeft: 12 }}><b style={{ fontFamily: 'Quasimoda', fontSize: 16 }}>{npsInfo.doc.seller}</b></div>
                            <div style={{ marginLeft: 12 }}>

                                <a href={`https://wa.me/${npsInfo.doc.phonenumber}`}>

                                    <b style={{ fontFamily: 'Quasimoda', fontSize: 12 }}>
                                        {npsInfo.doc.phonenumber ? npsInfo.doc.phonenumber : `--`}
                                    </b>
                                </a>
                            </div>
                        </div>

                    </div>
                    }

                </CardActions>
            </Card>
        </div>
    </Container>)
}