import axios from "axios";

async function getNPSData(organization, nps, id) {

    var hostname = "";
    if (!id)
        hostname = `https://us-central1-hackretail-92ffa.cloudfunctions.net/getNPSInfo?or=${organization}&nps=${nps}`
    else
        hostname = `https://us-central1-hackretail-92ffa.cloudfunctions.net/getNPSInfo?id=${id}`;

    var options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
    }

    return axios.post(hostname, {}, options)
        .then(a => {
            console.log(a);
            return a.data;
        })
        .catch(err => {
            //console.log(`erro calling api: `, err)
            return null;
        })
}
export default getNPSData;