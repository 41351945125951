import React from 'react';
import './App.css';
import NPS from './nps';


function App() {

  return (
    <NPS />
  );
}

export default App;
