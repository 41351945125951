import { CircularProgress } from "@mui/material";
import React from "react";
import getNPSData from "../api";
import NpsComponent from "./nps";
import ThankComponent from "./thank";

function NPS() {
    const [npsObject, setNPSObject] = React.useState(null);
    const [update, setUpdate] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {

        const func = async () => {

            setLoading(true);
            var queryParam = new URLSearchParams(window.location.search);

            /** new links without using rebrand.ly */
            const id = queryParam.get("id");

            const org = queryParam.get("or");
            const nps = queryParam.get("nps");

            const data = await getNPSData(org, nps, id);
            data.doc.npsid = data.doc.id;
            if (data) {
                setNPSObject(data);
            }

            setTimeout(() => { setLoading(false); }, 600);
        }
        func();

    }, [update])


    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress size={80} style={{ color: "#182642" }} />
        </div>
    }

    return (<div>
        {
            npsObject && npsObject.status === `already ansewered`
                ? <ThankComponent npsInfo={npsObject} />
                :
                <NpsComponent npsInfo={npsObject} onNpsAnswered={() => setUpdate(update + 1)} />
        }
    </div>)
}

export default NPS;