import { Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Divider, TextField } from "@mui/material";
import React from "react";
import setNPSAnswerAPI from "../api/setNps";



function NpsComponent({ npsInfo, onNpsAnswered }) {


    const [npsGeneral, setNpsGeneral] = React.useState(0);
    const [npsSeller, setNpsSeller] = React.useState(null);
    const [npsProduct, setNpsProduct] = React.useState(null);

    const [howToImprove, setHowToImprove] = React.useState(``);

    const [send, setSend] = React.useState(0);

    const [loading, setLoading] = React.useState(false);


    const DefaultDivider = () => {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
            <Divider sx={{ borderBottomWidth: 3 }} style={{ backgroundColor: '#021056', width: `40%`, }} />
        </div>
    }

    React.useEffect(() => {

        if (send === 0) return;

        if (!npsGeneral) {
            alert(`Por favor, selecione uma nota de Recomendação.`);
            setSend(0);
            return;
        }

        setLoading(true);

        //reset send button
        setSend(0);


        const tmpnpsProduct = npsInfo.doc.npsconfig.showproductquestion ? npsProduct : null;
        const tmpnpsSeller = npsInfo.doc.npsconfig.showsellerquestion ? npsSeller : null;

        setNPSAnswerAPI(npsInfo.doc.or, npsInfo.doc.npsid,
            npsGeneral, tmpnpsSeller, tmpnpsProduct, howToImprove)
            .then(() => {
                //update data
                onNpsAnswered();
            })
            .catch(() => {
                setLoading(false);
                alert(`Falha ao responder questionário. Por favor, tente novamente!`);
            });


    }, [send, npsGeneral, npsSeller, npsProduct, howToImprove])

    return (npsInfo &&
        <Card sx={{ maxWidth: 385 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CardMedia
                    component="img"
                    alt="brand"
                    style={{ marginTop: 12, padding: 2, height: "60%", width: `70%` }}
                    image={npsInfo.doc.npsconfig.urlimage}
                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
            </div>

            <CardContent>
                <div style={{ textAlign: 'center' }}>
                    <p style={{ fontFamily: npsInfo.doc.npsconfig.fontfamily, fontSize: 12 }}>
                        Olá, {npsInfo.doc.customerfirstname}.
                    </p>
                </div>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                    <p style={{ fontFamily: npsInfo.doc.npsconfig.fontfamily, fontSize: 14, wordSpacing: 1, lineHeight: 1.5 }}>
                        {npsInfo.doc.npsconfig.title}
                    </p>
                </div>

                <DefaultDivider />

                {/* 
                    NPS default question
                 */}
                <div style={{ textAlign: 'center', marginTop: 16 }}>
                    <p style={{ fontFamily: npsInfo.doc.npsconfig.fontfamily, fontSize: 14 }}>
                        {npsInfo.doc.npsconfig.recommendationquest}
                    </p>
                </div>
                <div style={{ flex: 1, }}>
                    <NPSNumber
                        bottomLabels={[`Pouco provável`, `Muito provável`]}
                        onSetNumber={setNpsGeneral}
                        npsInfo={npsInfo} />
                </div>
                <div style={{ marginTop: 6 }}>
                    <Divider />
                </div>


                {/* seller question */}
                {
                    npsInfo.doc.npsconfig.showsellerquestion &&
                    <>
                        <div style={{ textAlign: 'center', marginTop: 16 }}>
                            <p style={{ fontFamily: npsInfo.doc.npsconfig.fontfamily, fontSize: 14 }}>
                                {
                                    npsInfo.doc.npsconfig.sellerquestion.split("\\n").map((value, index) => {
                                        return value ? <div>{value}</div> : null
                                    })
                                }
                                {`${npsInfo.doc.sellername ? npsInfo.doc.sellername + "?" : ""}`}
                            </p>
                        </div>
                        <div style={{ flex: 1, }}>
                            <NPSNumber
                                bottomLabels={[`Pouco satisfeito`, `Muito satisfeito`]}
                                onSetNumber={setNpsSeller}
                                npsInfo={npsInfo} />
                        </div>
                        <div style={{ marginTop: 6 }}>
                            <Divider />
                        </div>
                    </>
                }

                {/* product question */}
                {
                    npsInfo.doc.npsconfig.showproductquestion &&
                    <>
                        <div style={{ textAlign: 'center', marginTop: 16 }}>
                            <p style={{ fontFamily: npsInfo.doc.npsconfig.fontfamily, fontSize: 14 }}>
                                {`${npsInfo.doc.npsconfig.productquestion}`}
                            </p>
                        </div>
                        <div style={{ flex: 1, }}>
                            <NPSNumber
                                bottomLabels={[`Pouco satisfeito`, `Muito satisfeito`]}
                                onSetNumber={setNpsProduct}
                                npsInfo={npsInfo} />
                        </div>
                        <div style={{ marginTop: 6 }}>
                            <Divider />
                        </div>
                    </>
                }


                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: 12,
                        }}>
                        <TextField multiline rows={1}
                            onInput={(v) => setHowToImprove(v.target.value)}
                            placeholder={`${npsInfo.doc.npsconfig.howimprovetitle}`}
                            style={{
                                width: '80%', borderRadius: 12, fontFamily: npsInfo.doc.npsconfig.fontfamily
                            }} />
                    </div>
                </div>

                <div style={{
                    marginTop: 12,
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {
                        !loading && <Button variant="contained"
                            onClick={() => setSend(1)}
                            style={{
                                backgroundColor: npsInfo.doc.npsconfig.primarycolor,
                                color: `#fff`,
                                borderRadius: 18,
                            }}>
                            <label style={{ fontFamily: npsInfo.doc.npsconfig.fontfamily }}>Enviar</label>
                        </Button>
                    }
                    {
                        loading && <CircularProgress style={{ color: npsInfo.doc.npsconfig.primarycolor }} />
                    }
                </div>
            </CardContent>
            <CardActions>
                <div>
                    <label
                        style={{
                            fontSize: 10
                        }}
                    >powered by WeRetail</label>
                </div>
            </CardActions>
        </Card>
    )

}

function NPSNumber({ bottomLabels, onSetNumber, npsInfo }) {

    const [ratings, setRatings] = React.useState([
        { value: 0, selected: false },
        { value: 1, selected: false },
        { value: 2, selected: false },
        { value: 3, selected: false },
        { value: 4, selected: false },
        { value: 5, selected: false },
        { value: 6, selected: false },
        { value: 7, selected: false },
        { value: 8, selected: false },
        { value: 9, selected: false },
        { value: 10, selected: false },
    ]);

    const changeRate = (index) => {

        const copyArr = [...ratings];

        for (let index = 0; index < copyArr.length; index++)
            copyArr[index].selected = false;

        copyArr[index].selected = !copyArr[index].selected;

        setRatings(copyArr);
        onSetNumber(copyArr[index].value)

    }

    return (ratings &&
        <div>
            <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {ratings.map((value, index) => {
                        return <div
                            onClick={(e) => { changeRate(index) }}
                            style={{ margin: 2 }}
                            key={`btn_${value.value}`}>
                            <button type="button"
                                style={{
                                    fontFamily: `${npsInfo.doc.npsconfig.fontfamily}`,
                                    fontWeight: `${value.selected ? 'bold' : ''}`,
                                    height: 28,
                                    width: 18,
                                    borderRadius: 8,
                                    border: `1.5px solid ${value.selected ? npsInfo.doc.npsconfig.primarycolor : npsInfo.doc.npsconfig.secondarycolor}`,
                                    backgroundColor: `transparent`,
                                    color: `${value.selected ? npsInfo.doc.npsconfig.primarycolor : '#000'}`,
                                    cursor: `pointer`,
                                    fontSize: value.selected ? 15 : 12,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                {value.value}
                            </button>
                        </div>
                    })}
                </div>
            </div>
            <div
                style={{ display: 'flex', marginTop: 12 }}>
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        justifySelf: 'flex-start',
                    }}>
                    <label style={{ fontFamily: npsInfo.doc.npsconfig.fontfamily, fontSize: 10, color: `#686868` }}>{bottomLabels[0]}</label>
                </div>
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        justifySelf: 'flex-end'
                    }}>
                    <label style={{ fontFamily: npsInfo.doc.npsconfig.fontfamily, fontSize: 10, color: `#686868` }}>{bottomLabels[1]}</label>
                </div>
            </div>
        </div>
    )
}




export default NpsComponent;