import axios from "axios";

async function setNPSAnswerAPI(organization, npsId, npsGeneral, npsSeller, npsProduct, howToImprove) {

    const hostname = `https://us-central1-hackretail-92ffa.cloudfunctions.net/setNPSInfo`;
    var options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
    }
    const data = {
        'or': organization,
        'npsId': npsId,
        'npsGeneral': npsGeneral,
        'npsSeller': npsSeller,
        'howToImprove': howToImprove,
        'npsProduct': npsProduct
    }
    return axios.post(hostname, data, options)
}
export default setNPSAnswerAPI;